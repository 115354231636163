<template>
  <MapPanzoom />

  <div class="container">
    <div class="container__legend">
      <div class="header-title">Decentralized Calcium Network</div>
      <div
        class="header-desc"
      >Open-source network of user-friendly internet services decentralized with Calcium Node software.</div>
    </div>

    <div class="footer-panel">
      <div class="footer-panel__socials">
        <a
          href="https://t.me/CalciumNetwork"
          target="_blank"
        >Telegram</a>
        <a
          href="https://twitter.com/CalciumNetwork"
          target="_blank"
        >Twitter</a>
        <a
          @click.prevent="isDonationsVisible = true"
          href="/"
          style="color: #F9CA24;"
        >Donations</a>
      </div>
    </div>
  </div>

  <transition name="fade">
    <DonationsModal
      @close="isDonationsVisible = false"
      v-show="isDonationsVisible"
    />
  </transition>
</template>

<script>
import { ref, onMounted } from "vue";
import MapPanzoom from "@/components/MapPanzoom";
import DonationsModal from "@/components/Donations";

export default {
  components: {
    DonationsModal,
    MapPanzoom
  },

  setup() {
    const isDonationsVisible = ref(false);

    onMounted(() => {
      window.addEventListener("gesturestart", e => e.preventDefault());
      window.addEventListener("gesturechange", e => e.preventDefault());
      window.addEventListener("gestureend", e => e.preventDefault());
    });

    return { isDonationsVisible };
  }
};
</script>

<style lang="scss">
body, html {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.container {
  position: fixed;
  display: flex;
  align-items: flex-end;
  bottom: 0px;
  left: 3vw;
  right: 3vw;
  bottom: 3vw;
  justify-content: space-between;
  pointer-events: none;
  z-index: 200;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  &__legend {
    text-align: left;

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}

.header-title {
  margin-bottom: 16px;
  color: #fff;
  font-family: Rubik;
  font-weight: 400;
  font-size: 28px;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 22px;
  }
}

.header-desc {
  text-align: left;
  line-height: 24px;
  color: #565669;
  font-family: Rubik;
  font-weight: normal;
  font-size: 18px;
  max-width: 30em;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
}

.footer-panel {
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 32px;
  background-color: #1c1c23;
  font-family: Rubik;
  pointer-events: auto;

  @media (max-width: 768px) {
    margin-top: 2em;
    margin-bottom: 1em;
  }
}

.footer-panel a {
  color: #fff;
  text-decoration: none;
}

.footer-panel__socials > * {
  margin: 0 20px;
}

.footer-panel__lang > * {
  padding: 10px 30px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
