export default {
  showOutlines: false,

  svgSize: [5200, 4000],

  tooltips: [
    // Calcium Project

    {
      id: 'calcium-project',
      left: 2430,
      top: 1845,
      width: 340,
      height: 300,
    },

    // Ufo project

    {
      id: 'ufo',
      left: 2465,
      top: 1363,
      width: 270,
      height: 270,
    },
    {
      id: 'ufo-blockchain',
      left: 2450,
      top: 1123,
      width: 104,
      height: 40,
    },
    {
      id: 'ufo-blockchain-uniasset',
      left: 1757,
      top: 915,
      width: 207,
      height: 40,
    },
    {
      id: 'ufo-blockchain-corewallet',
      left: 2064,
      top: 915,
      width: 105,
      height: 40,
    },
    {
      id: 'ufo-blockchain-taproot',
      left: 2269,
      top: 915,
      width: 82,
      height: 40,
    },

    {
      id: 'ufo-pool',
      left: 2578,
      top: 1123,
      width: 61,
      height: 40,
    },

    {
      id: 'ufo-explorer',
      left: 2663,
      top: 1123,
      width: 87,
      height: 40,
    },
    {
      id: 'ufo-explorer-omniexplorer',
      left: 2849,
      top: 915,
      width: 123,
      height: 40,
    },
    {
      id: 'ufo-explorer-omniexplorer-omniwallet',
      left: 3072,
      top: 915,
      width: 108,
      height: 40,
    },

    // Messenger

    {
      id: 'messenger',
      left: 1995,
      top: 1195,
      width: 270,
      height: 270,
    },
    {
      id: 'messenger-contacts',
      left: 1435,
      top: 1023,
      width: 160,
      height: 40,
    },
    {
      id: 'messenger-authentication',
      left: 1361,
      top: 1087,
      width: 234,
      height: 40,
    },
    {
      id: 'messenger-confirm',
      left: 1273,
      top: 1151,
      width: 322,
      height: 40,
    },
    {
      id: 'messenger-connection',
      left: 1358,
      top: 1215,
      width: 237,
      height: 40,
    },
    {
      id: 'messenger-mobile',
      left: 1485,
      top: 1279,
      width: 110,
      height: 40,
    },
    {
      id: 'messenger-mobile-android',
      left: 975,
      top: 1151,
      width: 84,
      height: 40,
    },
    {
      id: 'messenger-mobile-ios',
      left: 1005,
      top: 1215,
      width: 54,
      height: 40,
    },
    {
      id: 'messenger-encrypted',
      left: 1431,
      top: 1343,
      width: 164,
      height: 40,
    },
    {
      id: 'messenger-channels',
      left: 1419,
      top: 1407,
      width: 176,
      height: 40,
    },
    {
      id: 'messenger-sending-crypto',
      left: 1400,
      top: 1471,
      width: 195,
      height: 40,
    },
    {
      id: 'messenger-monetization',
      left: 1425,
      top: 1535,
      width: 170,
      height: 40,
    },
    {
      id: 'messenger-builtin',
      left: 1441,
      top: 1599,
      width: 154,
      height: 40,
    },

    // Network and services

    {
      id: 'network',
      left: 1690,
      top: 1870,
      width: 225,
      height: 260,
    },
    {
      id: 'network-api',
      left: 1324,
      top: 1860,
      width: 263,
      height: 40,
    },
    {
      id: 'network-services-monetized',
      left: 1364,
      top: 1980,
      width: 223,
      height: 40,
    },
    {
      id: 'network-services-swap',
      left: 924,
      top: 1916,
      width: 261,
      height: 40,
    },
    {
      id: 'network-services-swap-atomic',
      left: 341,
      top: 1492,
      width: 307,
      height: 40,
    },
    {
      id: 'network-services-swap-public',
      left: 506,
      top: 1556,
      width: 142,
      height: 40,
    },
    {
      id: 'network-services-swap-public-orderbook',
      left: 848,
      top: 1492,
      width: 212,
      height: 40,
    },
    {
      id: 'network-services-swap-client',
      left: 309,
      top: 1620,
      width: 339,
      height: 40,
    },
    {
      id: 'network-services-swap-authorization',
      left: 177,
      top: 1684,
      width: 471,
      height: 40,
    },
    {
      id: 'network-services-decentralised',
      left: 847,
      top: 1980,
      width: 338,
      height: 40,
    },
    {
      id: 'network-services-decentralised-storage',
      left: 512,
      top: 1852,
      width: 136,
      height: 40,
    },
    {
      id: 'network-services-decentralised-data',
      left: 511,
      top: 1916,
      width: 137,
      height: 40,
    },
    {
      id: 'network-services-decentralised-ipfs',
      left: 586,
      top: 1980,
      width: 62,
      height: 40,
    },
    {
      id: 'network-services-decentralised-monetization',
      left: 363,
      top: 2044,
      width: 285,
      height: 40,
    },
    {
      id: 'network-services-decentralised-monetization2',
      left: 361,
      top: 2108,
      width: 287,
      height: 40,
    },
    {
      id: 'network-services-distributed',
      left: 822,
      top: 2044,
      width: 363,
      height: 40,
    },
    {
      id: 'network-services-distributed-distributed',
      left: 469,
      top: 2276,
      width: 179,
      height: 40,
    },
    {
      id: 'network-services-distributed-monetization',
      left: 326,
      top: 2340,
      width: 322,
      height: 40,
    },
    {
      id: 'network-services-distributed-user',
      left: 523,
      top: 2404,
      width: 125,
      height: 40,
    },
    {
      id: 'network-services-distributed-developers',
      left: 515,
      top: 2468,
      width: 133,
      height: 40,
    },
    {
      id: 'network-voting',
      left: 1312,
      top: 2100,
      width: 275,
      height: 40,
    },

    // Content providers

    {
      id: 'content',
      left: 1995,
      top: 2535,
      width: 270,
      height: 270,
    },
    {
      id: 'content-broadcast',
      left: 1220,
      top: 2554,
      width: 375,
      height: 40,
    },
    {
      id: 'content-direct',
      left: 1224,
      top: 2618,
      width: 371,
      height: 40,
    },
    {
      id: 'content-monetization',
      left: 1425,
      top: 2682,
      width: 170,
      height: 40,
    },
    {
      id: 'content-decentralized',
      left: 1262,
      top: 2746,
      width: 333,
      height: 40,
    },

    // Calcium node

    {
      id: 'calcium-node',
      left: 2935,
      top: 2535,
      width: 270,
      height: 270,
    },
    {
      id: 'calcium-p2p',
      left: 3605,
      top: 2458,
      width: 233,
      height: 40,
    },
    {
      id: 'calcium-builtin',
      left: 3605,
      top: 2522,
      width: 180,
      height: 40,
    },
    {
      id: 'calcium-messages',
      left: 3605,
      top: 2586,
      width: 132,
      height: 40,
    },
    {
      id: 'calcium-validation',
      left: 3605,
      top: 2650,
      width: 409,
      height: 40,
    },
    {
      id: 'calcium-network',
      left: 3605,
      top: 2714,
      width: 152,
      height: 40,
    },
    {
      id: 'calcium-network-tox',
      left: 4205,
      top: 2778,
      width: 58,
      height: 40,
    },
    {
      id: 'calcium-network-i2p',
      left: 4205,
      top: 2842,
      width: 52,
      height: 40,
    },
    {
      id: 'calcium-network-tor',
      left: 4205,
      top: 2906,
      width: 59,
      height: 40,
    },
    {
      id: 'calcium-administration',
      left: 3605,
      top: 2778,
      width: 191,
      height: 40,
    },
    {
      id: 'calcium-rules',
      left: 3605,
      top: 2842,
      width: 205,
      height: 40,
    },

    // Ecosystem for devlopers

    {
      id: 'ecosystem',
      left: 3261,
      top: 1865,
      width: 270,
      height: 270,
    },
    {
      id: 'ecosystem-open',
      left: 3831,
      top: 1820,
      width: 475,
      height: 40,
    },
    {
      id: 'ecosystem-open-accounts',
      left: 4501,
      top: 1628,
      width: 172,
      height: 40,
    },
    {
      id: 'ecosystem-open-remote',
      left: 4501,
      top: 1692,
      width: 373,
      height: 40,
    },
    {
      id: 'ecosystem-open-abstraction',
      left: 4501,
      top: 1756,
      width: 325,
      height: 40,
    },
    {
      id: 'ecosystem-open-transport',
      left: 4501,
      top: 1820,
      width: 379,
      height: 40,
    },
    {
      id: 'ecosystem-open-developer',
      left: 4501,
      top: 1884,
      width: 232,
      height: 40,
    },
    {
      id: 'ecosystem-open-tools',
      left: 4501,
      top: 1948,
      width: 233,
      height: 40,
    },
    {
      id: 'ecosystem-webapi',
      left: 3831,
      top: 1884,
      width: 319,
      height: 40,
    },
    {
      id: 'ecosystem-full',
      left: 3831,
      top: 1948,
      width: 310,
      height: 40,
    },
    {
      id: 'ecosystem-hll',
      left: 3831,
      top: 2012,
      width: 472,
      height: 40,
    },
    {
      id: 'ecosystem-training',
      left: 3831,
      top: 2076,
      width: 434,
      height: 40,
    },
    {
      id: 'ecosystem-public',
      left: 3831,
      top: 2140,
      width: 461,
      height: 64,
    },
    {
      id: 'ecosystem-public-working',
      left: 4501,
      top: 2116,
      width: 180,
      height: 40,
    },
    {
      id: 'ecosystem-public-working2',
      left: 4501,
      top: 2180,
      width: 197,
      height: 40,
    },
    {
      id: 'ecosystem-public-working3',
      left: 4501,
      top: 2244,
      width: 165,
      height: 40,
    },
    {
      id: 'ecosystem-public-working4',
      left: 4501,
      top: 2308,
      width: 252,
      height: 40,
    },

    // Calcium Wallet

    {
      id: 'calciumwallet',
      left: 2935,
      top: 1195,
      width: 270,
      height: 270,
    },
    {
      id: 'calciumwallet-platforms',
      left: 3605,
      top: 1055,
      width: 96,
      height: 40,
    },
    {
      id: 'calciumwallet-platforms-web',
      left: 4205,
      top: 799,
      width: 60,
      height: 40,
    },
    {
      id: 'calciumwallet-platforms-desktop',
      left: 4205,
      top: 863,
      width: 86,
      height: 40,
    },
    {
      id: 'calciumwallet-platforms-iphone',
      left: 4205,
      top: 927,
      width: 78,
      height: 40,
    },
    {
      id: 'calciumwallet-platforms-android',
      left: 4205,
      top: 991,
      width: 84,
      height: 40,
    },
    {
      id: 'calciumwallet-services',
      left: 3605,
      top: 1119,
      width: 340,
      height: 40,
    },
    {
      id: 'calciumwallet-directory',
      left: 3605,
      top: 1183,
      width: 272,
      height: 40,
    },
    {
      id: 'calciumwallet-atomicswap',
      left: 3605,
      top: 1247,
      width: 254,
      height: 40,
    },
    {
      id: 'calciumwallet-ufotokens',
      left: 3605,
      top: 1311,
      width: 107,
      height: 40,
    },
    {
      id: 'calciumwallet-authorization',
      left: 3605,
      top: 1375,
      width: 283,
      height: 40,
    },
    {
      id: 'calciumwallet-data',
      left: 3605,
      top: 1439,
      width: 266,
      height: 40,
    },
    {
      id: 'calciumwallet-nft',
      left: 3605,
      top: 1503,
      width: 183,
      height: 40,
    },
    {
      id: 'calciumwallet-ufointegration',
      left: 3605,
      top: 1567,
      width: 135,
      height: 40,
    },
  ],
}
