<template>
  <!--TooltipsItem
    :current-tooltip-id="currentTooltipId"
    tooltipId="calcium-project"
  >
    <div class="header">Chat contacts</div>
    <p>The innovative blending of several hashing algorithms provides strong encryption, thus complete safety of protocol.</p>
    <a
      href="https://ufobject.com/#download"
      target="_blank"
    >ufobject.com/#download</a>
  </TooltipsItem-->
  
  
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-administration"
>
  <div class="header">Administering Calcium Node</div>
  <p>
Calcium Node has a built-in local service that provides an adminstrative interface for
easy node configuring and tuning.</p><p>Additional network-exposed services can be setup and enabled using this interface.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-builtin"
>
  <div class="header">Built-in UFO Core node</div>
  <p>
UFO Core node is built into Calcium Node. It increases the number of UFO nodes which
support UFO network and blockchain.</p><p>Built-in UFO Core node is also used for real-time analyzing of Calcium related data 
stored on the UFO blockchain.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-messages"
>
  <div class="header">Messages relay</div>
  <p>
Calcium Node provides messages relay among nodes, users and services,
based on the internal network naming system. Messages are routed over the
supported underlying transport protocols.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-network-i2p.off"
>
  <div class="header">calcium-network-i2p.off</div>
  <p></p>
  <a
    href="calcium-network-i2p.off"
    target="_blank"
  >calcium-network-i2p.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-network-tor.off"
>
  <div class="header">calcium-network-tor.off</div>
  <p></p>
  <a
    href="calcium-network-tor.off"
    target="_blank"
  >calcium-network-tor.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-network-tox.off"
>
  <div class="header">calcium-network-tox.off</div>
  <p></p>
  <a
    href="calcium-network-tox.off"
    target="_blank"
  >calcium-network-tox.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-network"
>
  <div class="header">Network protocols</div>
  <p>
Calcium.Network supports several underlying open-source transport protocols for
data transmission and message relay.</p><p>The target goal is to provide an interoperability of all the connected sibling nodes
despite of the set of protocols they use.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-node"
>
  <div class="header">Calcium Node</div>
  <p>
Calcium Node is a software which implements
internode connections for p2p networking, messages relaying,
tunnels to Calcium services, a builtin extended UFO node, and a predefined 
set of service implementations which can be run locally by the node's
owner, such as SwapDEX orderbook, decentralized storage and distributed 
computing.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-p2p"
>
  <div class="header">P2P connections</div>
  <p></p><p>Calcium Node is built to support different underlying transport protocols simultaniously.
This improves connectivity to Calcium Network from within different environments.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-project"
>
  <div class="header">Calcium.Network</div>
  <p>
The decentralized network for sending messages, accessing the content, 
paying and receiving crypto, running services and monetizing
it with UFO (Uniform Fiscal Object).</p><p>Unites open-source technologies into the single ecosystem.
</p>
  <a
    href="https://calcium.network/index2.html"
    target="_blank"
  >Calcium.Network website</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-rules"
>
  <div class="header">Rules validation</div>
  <p>
Some of the Calcium.Network specific voting and rules is stored on the UFO blockchain. 
Extra validation is required to provide similar behavior of all the Calcium Nodes 
participating in network and provide network stability.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calcium-validation"
>
  <div class="header">Data validation</div>
  <p>
Some of the Calcium.Network specific data (data registration, public ids, channel ownership and permissions)
is stored on the UFO blockchain. </p><p>Extra data validation is required to provide Calcium specific behavior
of all of the Calcium Nodes participating in network.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-atomicswap"
>
  <div class="header">Atomic Swap exchange</div>
  <p></p><p>Secure atomic swap exchange scenario is to be built into the Calcium Wallet.
It allows to exchange crypto between two blockchains without any need to
trust a 3rd party.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-authorization"
>
  <div class="header">Authorize with Calcium Messenger</div>
  <p></p><p>Calcium Messenger supports multiple user accounts that
can be used for authorization in Calcium enabled applications.</p><p>Work in progress to make it OAuth compatible.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-data"
>
  <div class="header">Calcium data on UFO blockchain</div>
  <p></p><p>Calcium applications use UFO blockchain as a historical registry
for storing important information about ownership claims and 
access rights management for different purposes.</p><p>Calcium Wallet is expected to provide the initial interface for
inspecting such kind of data.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-directory"
>
  <div class="header">Directory of 3rd party services</div>
  <p>
A list of service providers that are already integrated into the Calcium Wallet interface.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-nft"
>
  <div class="header">NFT on UFO blockchain</div>
  <p></p><p>With the release of <b>Uniasset</b>, a tokenization layer
appears on UFO blockchain.</p><p>Calcium Wallet is expected to provide a user-friendly interface for issuing and managing
non-fungible tokens (NFTs).
</p>
  <a
    href="https://uniasset.org"
    target="_blank"
  >Uniasset website</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-platforms-android.off"
>
  <div class="header">calciumwallet-platforms-android.off</div>
  <p></p>
  <a
    href="calciumwallet-platforms-android.off"
    target="_blank"
  >calciumwallet-platforms-android.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-platforms-desktop"
>
  <div class="header">Calcium Wallet for Desktop</div>
  <p></p><p>The most self-sufficient version of Calcium Wallet which is
not dependant on any specific server. By default, known public servers are used for fetching blockchain data.</p><p>In most secure scenarios desktop wallet can be configured to
use only trusted datasources - local servers, APIs, etc.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-platforms-iphone.off"
>
  <div class="header">calciumwallet-platforms-iphone.off</div>
  <p></p>
  <a
    href="calciumwallet-platforms-iphone.off"
    target="_blank"
  >calciumwallet-platforms-iphone.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-platforms-web"
>
  <div class="header">Calcium Wallet for Web</div>
  <p></p><p>A browser based version of the non-custodial Calcium Wallet.</p><p>It doesn't pass any sensitive information to servers, but is dependant
on the server-side backend for fetching blockchain data.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-platforms"
>
  <div class="header">Calcium Wallet is multiplatform</div>
  <p>
Multiplatform support allows to use same
accounts with same wallets on multiple devices.
</p>
  <a
    href="https://wallet.calcium.network"
    target="_blank"
  >Calcium Wallet for Web</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-services"
>
  <div class="header">Calcium Services in Wallet</div>
  <p>
3rd party services announce themselves on the Calcium Network and provide 
functionality with  user-friendly integration into the Calcium Wallet and 
Calcium Messenger.</p><p>Initial development is aimed towards API for simple services, such as crypto 
exchanges.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-ufointegration"
>
  <div class="header">Full UFO support</div>
  <p>
A comprehensive support for UFO coin, tokens and any features that
UFO blockchain implements.
</p>
  <a
    href="https://ufobject.com"
    target="_blank"
  >UFO website</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet-ufotokens"
>
  <div class="header">Uniasset tokens layer</div>
  <p>
Calcium Wallet implements support for Uniasset tokenization built over
UFO blockchain. Includes management of OMNI tokens and NFTs right in the wallet.
</p>
  <a
    href="https://uniasset.org"
    target="_blank"
  >Uniasset website</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="calciumwallet"
>
  <div class="header">Calcium Wallet</div>
  <p>
Web-version of the dedicated cryptowallet specifically designed
to support UFO, Uniasset, specific Calcium related functionality 
and also multiple blockchains.
</p>
  <a
    href="https://wallet.calcium.network"
    target="_blank"
  >Calcium Wallet for Web</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="content-broadcast"
>
  <div class="header">Broadcast to Calcium channels</div>
  <p></p><p>Calcium Library provides a set of feature to enable
broadcasting content directly into the public
channels.</p><p>Channel aware applications can consume and present
the specified content in a user-friendly manner.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="content-decentralized"
>
  <div class="header">Content platform</div>
  <p>
Content platform is being developed to connect content providers with users 
using UFO as the payment media and integrated Calcium Wallet features.</p><p>Future plans are to implement a decentralized version of the platform
working over the Calcium.Network.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="content-direct"
>
  <div class="header">Content channels in Calcium Messenger</div>
  <p>
As as a part of integration Calcium Messenger 
will support downloading text and visuals
from diffrenet content providers that transmit
their content via the Calcium.Network.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="content-monetization"
>
  <div class="header">Subscriptions and donations</div>
  <p></p><p>Users can support favorite channels and respective
content providers with a built-in payment media by
subscribing or donating to channels owners 
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="content"
>
  <div class="header">Content Providers</div>
  <p>
As as a part of real-life application Calcium Network
is aimed towards integration with <b>content providers</b>.
They can deliver useful content using Calicum APIs and
native network features.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-full"
>
  <div class="header">Documentation for developers</div>
  <p></p><p>Calcium Library and APIs will have online documentation 
generated and updated at the intermediate and release stages.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-hll"
>
  <div class="header">High level language Calcium Library</div>
  <p></p><p>High level language library is planned for developement as a binding for
any compileable language - C++, Go and others.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-open-abstraction"
>
  <div class="header">Blockchain abstraction layer (BAL)</div>
  <p>
BAL is a significant part of the Calcium Library.
It implements coin drivers, blockchain databases and datasources
for extracting blockchain data from public sources.</p><p>BAL provides support for transactions formats, additional layers,
contracts and other features, presenting it in a unified way 
for all supported blockchains.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-open-accounts"
>
  <div class="header">Accounts and wallets</div>
  <p>
Calcium Library introduces accounts, that can be generated from different identities.
Accounts are used to identify user within the application.  They also can be used to generate 
deterministic wallets inside the app.</p><p>Wallets are a simple way to manage funds on different blockchains and different sets of
addresses and contracts.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-open-developer"
>
  <div class="header">Developer's API for services</div>
  <p></p><p>Calcium Node provides an API for custom services that can connect to
Calcium.Network, accept requests and feed data back into the network.</p><p>This is the same API used for built-in services, such as orderbook or decentralized
storage. </p><p>Developers can use it to create custom Calcium enabled services.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-open-remote"
>
  <div class="header">Remote accounts</div>
  <p>
On its way to the decentralized identities, Calcium Library is being
developed with the support for remote accounts in mind. Remote accounts
can be used for authenticating Calcium enabled web applications but
without granting them access to private keys. Key management and signing 
is done by the client-side application.</p><p>Remote accounts can be stored in Calcium Messenger, or any other 
application that supports Calicum remote accounts, or even with a custom
written authentication package.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-open-tools"
>
  <div class="header">Creating new Calcium services</div>
  <p></p><p>Examples of services will be provided with a detailed documentation.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-open-transport"
>
  <div class="header">Calcium Transport</div>
  <p>Calcium Library includes a module to establish connections 
 with Calcium.Network and receive/transmit data from different environments
using a full set or a subset of available transports.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-open"
>
  <div class="header">For advanced developers</div>
  <p>
Calcium Library written in Typescript is a comprehensive open-source
library which includes all the features that are available at
Calcium.Network.</p><p>Provides abstraction layers to manage accounts and crypto wallets,
network connectivity and more.</p><p>Well suited for advanced developers.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-public-working.off"
>
  <div class="header">ecosystem-public-working.off</div>
  <p></p>
  <a
    href="ecosystem-public-working.off"
    target="_blank"
  >ecosystem-public-working.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-public-working2.off"
>
  <div class="header">ecosystem-public-working2.off</div>
  <p></p>
  <a
    href="ecosystem-public-working2.off"
    target="_blank"
  >ecosystem-public-working2.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-public-working3.off"
>
  <div class="header">ecosystem-public-working3.off</div>
  <p></p>
  <a
    href="ecosystem-public-working3.off"
    target="_blank"
  >ecosystem-public-working3.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-public-working4.off"
>
  <div class="header">ecosystem-public-working4.off</div>
  <p></p>
  <a
    href="ecosystem-public-working4.off"
    target="_blank"
  >ecosystem-public-working4.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-public"
>
  <div class="header">Frontend for Calcium WebAPI</div>
  <p></p><p>Frontend allows developers to sign up for using
Calcium WebAPI, configure access tokens, explore and 
test API calls from web interface.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-training"
>
  <div class="header">Tutorials and examples</div>
  <p>
Along with the documentation basic examples
will be provided for the most important functionality.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem-webapi"
>
  <div class="header">Simple Web API</div>
  <p></p><p>
Calcium.Network provides a centralized service with simplified
web API for managing accounts and wallets.</p><p>Suitable for junior developers and those who are just 
starting to learn Calcium ecosystem.</p><p>Calcim WebAPI provides an RPC interface served 
over HTTP. It has a very simple call and response formats for
easier integration.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ecosystem"
>
  <div class="header">Calcium for developers</div>
  <p>
Calcium has a set of tools for developers: libraries, web-api and documentation.</p><p>Tools are designed to provide access to all of the Calcium features that allow
developers to create applications with a truly decentralized functionality.</p><p>Tools are evolving along with the Calcium.Network and are being constantly updated as
the development goes on.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-authentication"
>
  <div class="header">Authentication for Apps</div>
  <p></p><p>Calcium.Network allows applications to request remote
authentication from your Calcium Messenger.</p><p>This way you can easily store specific identities and permissions 
for every application, without the need to disclose your email or
password to the third-party.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-builtin"
>
  <div class="header">Built-in crypto wallet</div>
  <p>
Calcium Messenger has a native support for
crypto (UFO, BTC) and Uniasset based tokens. 
This makes  it possible for users to send crypto 
directly in chats, or use it as a payment medium  
for channels and services.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-channels"
>
  <div class="header">Channels with content</div>
  <p></p><p>Channels are a good way for <b>Content Providers</b> to deliver content 
to the end user and are a basic feature of Calcium Network.</p><p>Different type of channels (public, private and promoted) are smoothly integrated
into the messenger, using native Calcium access rights management, payment options
and network's spam protection.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-confirm"
>
  <div class="header">Permissions for Apps</div>
  <p></p><p>Calcium enabled applications will ask you
to sign some specific important actions, such as messages
or transactions. This way other users can verify
that actions are originated from your identity.</p><p>You can confirm such actions in Calcium Messenger.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-connection"
>
  <div class="header">Connection to Calcium.Network</div>
  <p></p><p>Calcium Network is run by a number of indepent nodes
that relay messages and network data.</p><p>Calcium Messenger will connect to available nodes
for transmitting messages and get access to other services
on the network.</p><p>This way messenger app is supposed to work in the truly decentralized manner.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-contacts"
>
  <div class="header">Contacts and chats</div>
  <p></p><p>Confidential chats with contacts using additional level of p2p encryption.</p><p>Ability to send crypto directly to your contact right in the chat window.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-encrypted"
>
  <div class="header">Accounts encryption</div>
  <p></p><p>In opposite to most of other messengers,
Calcium Messenger enforces the encryption of the
accounts data stored on your device.</p><p>This requires some trade-off between the speed
and security though, but prevents your
sensitive data from becoming occasionally available
in case of the device loss.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-mobile-android.off"
>
  <div class="header">messenger-mobile-android.off</div>
  <p></p>
  <a
    href="messenger-mobile-android.off"
    target="_blank"
  >messenger-mobile-android.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-mobile-ios.off"
>
  <div class="header">messenger-mobile-ios.off</div>
  <p></p>
  <a
    href="messenger-mobile-ios.off"
    target="_blank"
  >messenger-mobile-ios.off</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-mobile"
>
  <div class="header">Calcium Messenger for mobile</div>
  <p></p><p>Prototype of the Calcium Messenger is being developed
as a PWA application, which can be installed directly
from the developer's website.</p><p>This requires no need for the app to be published in
the applications store (Apple Store or Google Play)
and gives much more flexibility in features available 
in the Calcium Messenger.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-monetization"
>
  <div class="header">Monetizing with UFO</div>
  <p></p><p>Monetizing options for all users of Calcium Messenger and
members of Calcium Network are being kept in mind during the
developement. This is a sensitive issue which is carefully 
planned as a part of the network's architecture.</p><p>Developers are in permanent search of the well-balanced solutions 
for members of the every level of participation in the ecosystem.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger-sending-crypto"
>
  <div class="header">Sending crypto</div>
  <p></p><p>Integrated wallet gives you an option to
send crypto (UFO and Uniasset layer tokens) in single click 
to your contacts, donate to channels' authors and pay
for services inside the app.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="messenger"
>
  <div class="header">Calcium Messenger</div>
  <p></p><p>Multiplatform encrypted messenger with a builtin non-custodial crypto wallets.
Messages are transmitted over p2p networks, such as Tor and I2P.</p><p>Enables 2-factor authentication for Calcium enabled application.
No phone numbers or emails needed to create an account.</p><p>UFO is used a payment medium.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-api"
>
  <div class="header">API for services</div>
  <p></p><p>Calcium Node provides network endpoints for services and
proxifies all the service related traffic to the proper service
implementation.</p><p>Special API provided by the node allows developers to create custom
services. Developers can use any language/framework to implement the service backend.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-decentralised-data"
>
  <div class="header">Data encryption</div>
  <p>
All the data stored in Calcium Storage is encrypted with your keys before
being transmitted to the storage nodes.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-decentralised-ipfs"
>
  <div class="header">Calcium Storage over IPFS</div>
  <p></p><p>IPFS is researched to be used as one of the storage layers for 
Calcium Storage.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-decentralised-monetization"
>
  <div class="header">Monetizing private data</div>
  <p></p><p>Users can store private encrypted data and solely access it by themselves only.
Storing such data generates expenses for the Calcium Node owner and might be
covered with some payment made in UFO according to the pricing strategy.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-decentralised-monetization2"
>
  <div class="header">Monetizing public data</div>
  <p>
Calcium Storage service might act as a public content delivery node.
In opposite to private data, storing public data requires not only
a free space but also a significant network bandwidth which might affect the
whole network.</p><p>This issue to be researched yet.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-decentralised-storage"
>
  <div class="header">Storage service</div>
  <p>
Owners of Calcium Node can enable utilization of free space via the Calcium Storage service.
The amount of free space and pricing strategy is determined by the node's owner.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-decentralised"
>
  <div class="header">Decentralized Storage</div>
  <p></p><p>Calcium users are able to share a free space and store encrypted data from other network members.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-distributed-developers"
>
  <div class="header">Distributed Computing Developer's API</div>
  <p></p><p>Complex periodical tasks might require a deeper integration with the 
distributed computing infrastructure.</p><p>Calcium Node is supposed to provide a comprehensive API which can
be used for integration with other software via HTTP/RPC/plugin interfaces.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-distributed-distributed"
>
  <div class="header">Distributed Computing service</div>
  <p></p><p>
Owners of Calcium Node can enable utilization of spare CPU/GPU time via the Calcium Distributed Calculations service. 
The load of CPU/GPU and pricing strategy is determined by the node's owner.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-distributed-monetization"
>
  <div class="header">Monetizing calculations</div>
  <p>
Calcium Node owners can configure the parameters of hardware available for calculations.
Executing calculation generates expenses for the Calcium Node owner and might be covered with 
some payment made in UFO according to the pricing strategy.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-distributed-user"
>
  <div class="header">Distributed Computing Basic UI</div>
  <p></p><p>A user friendly interface which allows to test drive a distributed
computing for relatively simple or one-time tasks.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-distributed"
>
  <div class="header">Distributed Computing</div>
  <p></p><p>Calcium users that have Calcium Node running on a dedicated hardware
might want to share CPU time for other network members' tasks, such
as mathematical calculations, GPU rendering and similar.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-monetized"
>
  <div class="header">Useful services</div>
  <p></p><p>There are several network-wide services that can be 
easily run by any user.</p><p>This allows users to monetize their effort and
compensate to some degree the resources that are needed 
to keep the Calcium Node running, thus supporting the network
as a whole.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-swap-atomic"
>
  <div class="header">Atomic Swap scenario</div>
  <p></p><p>Atomic Swap scenario allows to exchange crypto between
blockchains in a non-custodial manner, without any need
for a 3rd party intermediary.</p><p>Calcium Library provides an implementation for executing
such scenario from different software - wallets,
messenger, dedicated DEX client.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-swap-authorization"
>
  <div class="header">Stay online for atomic swaps</div>
  <p></p><p>
Calcium Messengers gives SwapDEX users a feature of staying online during the exchange process.
It also allows to control funds and confirm all the transactions during the exchange. 
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-swap-client"
>
  <div class="header">SwapDEX client software</div>
  <p>
A dedicated non-custodial software that gives Calcium users
the most comfortable and secure way to exchange
crypto using atomics, lightning and other features
of different blockchains.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-swap-public-orderbook"
>
  <div class="header">SwapDEX Public orderbook</div>
  <p></p><p>Calcium Node owners may enable Public Orderbook 
service to provide a better availability of SwapDEX
features for the Calcium Network users.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-swap-public"
>
  <div class="header">Public Orderbook</div>
  <p>Public orderbook is an important part of SwapDEX - 
a decentralized exchange based on Atomic Swaps.
It solves the issue of maintaining orders and finding 
an active counterparty to execute the secure exchange.</p><p>Orderbook data is relayed among nodes that support
SwapDEX. UFO blockchain is used to manage orders lifespan.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-services-swap"
>
  <div class="header">SwapDEX</div>
  <p>
As the step towards secure crypto exchange, 
SwapDEX service implements a decentralized crossblockchain exchange - 
a set of features to support the public orderbook for atomic swap
exchanges and carry out non-custodial exchange operations.</p><p>This will allow SwapDEX aware software to carry
out atomic swap exchanges without a need to 
trust a 3rd party.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network-voting"
>
  <div class="header">Concept of Voting</div>
  <p></p><p>Since Calcium.Network is a decentralized network, it has to be governed by a 
minimalistic set of rules that keep the network in a consistent state.
Such rules are stored on the UFO blockchain. </p><p>The most common concept is that all Calcium Nodes follow the updates and apply 
those rules while relaying network data. To update the conditions or costs of the 
rules anyone may initiate voting that can be supported or objected by other parties.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="network"
>
  <div class="header">Network services</div>
  <p></p><p>Services are similar to web-sites for users, or APIs for developers.
They implement additional features that are available on the Calcium.Network</p><p>Calcium Node software provides a connectivity with other nodes and works
as a gateway that grants the access to the network services.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ufo-blockchain-corewallet"
>
  <div class="header">UFO Core</div>
  <p>
UFO Core is a fork of the Bitcoin core project. It contains all the original 
well-tested functionality of the open-source Bitcoin Core wallet.
</p>
  <a
    href="https://github.com/fiscalobject/ufo"
    target="_blank"
  >Binary and source code on Github</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ufo-blockchain-taproot"
>
  <div class="header">Taproot for UFO</div>
  <p>
Taproot improves UFO blockchain scripts to increase privacy and improve 
upon other factors related to complex transactions. 
Transactions on the UFO network can use various features that make them more complex, 
including timelock releases, multi-signature requirements, and others.
</p>
  <a
    href=""
    target="_blank"
  ></a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ufo-blockchain-uniasset"
>
  <div class="header">Uniasset Wallet</div>
  <p>
Uniasset is an extended version of UFO Core wallet with built-in support
for tokenization layer over UFO Blockchain.</p><p>Supports OMNI tokens and NFTs.
</p>
  <a
    href="https://uniasset.org"
    target="_blank"
  >Uniasset website</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ufo-blockchain"
>
  <div class="header">UFO blockchain</div>
  <p>
<ul><li>Proof of Work (PoW)</li>
<li>NeoScrypt hashing algorithm</li>
<li>90 sec block time</li>
<li>~4 billion coins total</li>
<li>6 confirmations per transaction</li>
<li>Genesis 2nd of January 2014</li>
</ul></p>
  <a
    href="https://ufobject.com"
    target="_blank"
  >UFO coin website</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ufo-explorer-omniexplorer-omniwallet"
>
  <div class="header">OMNI Wallet</div>
  <p>
Web wallet and browser extension
for managing OMNI tokens over UFO blockchain.
</p>
  <a
    href=""
    target="_blank"
  >OMNI wallet website</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ufo-explorer-omniexplorer"
>
  <div class="header">OMNI Explorer</div>
  <p>
Blockchain explorer with OMNI layer support over UFO blockchain.
</p>
  <a
    href=""
    target="_blank"
  >OMNI Explorer website</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ufo-explorer"
>
  <div class="header">UFO Explorer</div>
  <p>
Research UFO blockhain transaction, inputs and blocks.
</p>
  <a
    href="https://explorer.ufobject.com"
    target="_blank"
  >Official UFO Explorer</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ufo-pool"
>
  <div class="header">UFO Mining Pool</div>
  <p></p>
  <a
    href="https://pool.ufobject.com"
    target="_blank"
  >Official UFO Mining Pool Website</a>
</TooltipsItem>
<TooltipsItem
  :current-tooltip-id="currentTooltipId"
  tooltipId="ufo"
>
  <div class="header">Uniform Fiscal Object (UFO)</div>
  <p>
Cryptocurrency used as a payment medium and 
storage layer for Calcium.Network.</p><p>Proof-of-work with advanced blockchain
protection features - ASIC-resistant and advanced
checkpoints.
</p>
  <a
    href="https://ufobject.com"
    target="_blank"
  >UFO coin website</a>
</TooltipsItem>

</template>


<script>
import TooltipsItem from "@/components/TooltipsItem";

export default {
  components: {
    TooltipsItem
  },

  props: {
    currentTooltipId: String
  },

  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-size: 22px;
  line-height: 33px;
}

li {
  margin: 0px;
}

p {
  font-size: 14px;
  line-height: 24px;
}

a, a:visited, a:active, a:focus {
  color: #f0932b;
  text-decoration: none;
}

* + * {
  margin-bottom: 20px;
}
</style>
