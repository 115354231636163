<template>
  <div
    class="map"
    ref="map"
  >
    <div
      :style="{
        position: 'relative',
        height: config.svgSize[1] * 2 + 'px',
        width: config.svgSize[0] * 2 + 'px',
        overflow: 'hidden'
      }"
      @mouseover="handleMouseLeave"
      class="tooltipable panzoomEl"
      ref="panzoomEl"
    >
      <div
        :data-id="tooltip.id"
        :key="tooltip.id"
        :style="{
          left: tooltip.left + 'px',
          top: tooltip.top + 'px',
          width: tooltip.width + 'px',
          height: tooltip.height + 'px',
          border: config.showOutlines ? '4px solid red' : false,
        }"
        @mouseleave="handleMouseLeave"
        @mouseover="handleMouseOver(tooltip.id, $event)"
        class="tooltipable area"
        v-for="tooltip in config.tooltips"
        v-touch:tap="handleAreaTap"
      />

      <SvgGraph />
    </div>
  </div>

  <div
    @mouseleave="handleTooltipMouseLeave"
    class="tooltip"
    id="tooltip"
    ref="tooltip"
    role="tooltip"
  >
    <Tooltips :current-tooltip-id="tooltipId" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import panzoom from "panzoom";
import { createPopper } from "@popperjs/core";
import SvgGraph from "@/components/SvgGraph";
import Tooltips from "@/components/Tooltips";
import config from "@/tooltips";

export default {
  components: {
    SvgGraph,
    Tooltips
  },

  setup() {
    const tooltip = ref(null);
    const panzoomEl = ref(null);
    const tooltipId = ref("");

    let popperInstance;

    let hasTouchScreen = false;

    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      let mQ = window.matchMedia && matchMedia("(pointer:coarse)");

      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    const closeTooltip = () => {
      popperInstance && popperInstance.destroy();
      tooltip.value.removeAttribute("data-show");
    };

    const handleAreaTap = e => {
      const dataId = e.target.getAttribute("data-id");
      tooltipId.value = dataId;
      openTooltip(e.target);
    };

    const openTooltip = target => {
      popperInstance = createPopper(target, tooltip.value, {
        placement: "right",
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["left", "top"],
              padding: 0
            }
          },
          {
            name: "offset",
            options: {
              offset: [0, 0]
            }
          }
        ]
      });

      tooltip.value.setAttribute("data-show", "");
    };

    const handleTooltipMouseLeave = e => {
      closeTooltip();

      if (e.relatedTarget && e.relatedTarget.closest(".tooltipable.area")) {
        setImmediate(() => {
          openTooltip(e.relatedTarget);
        });
      }
    };

    const handleMouseOver = (dataId, e) => {
      tooltipId.value = dataId;

      if (hasTouchScreen) {
        // ignore touch events
        return false;
      }

      e.stopPropagation();

      const tooltipable = e.target.closest(".tooltipable");

      if (!tooltipable) {
        closeTooltip();
        return false;
      }

      if (!tooltip.value.hasAttribute("data-show")) {
        openTooltip(tooltipable);
      }
    };

    const handleMouseLeave = e => {
      if (hasTouchScreen) {
        // ignore touch events
        return false;
      }

      const { relatedTarget } = e;

      if (relatedTarget) {
        if (!relatedTarget.closest(".tooltip")) {
          closeTooltip();
        }
      }
    };

    const initPanzoom = () => {
      const instance = panzoom(panzoomEl.value, {
        maxZoom: 1.5,
        minZoom: 0.2,
        bounds: true,
        boundsPadding: 0.4,
        onTouch: e => {
          const target = e.target.className;

          if (target !== "tooltipable area") {
            closeTooltip();
          }

          e.preventDefault();

          return true;
        }
      });

      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      const x = config.svgSize[0] / 2 - width / 2;
      const y = config.svgSize[1] / 2 - height / 2;

      setTimeout(() => {
        instance.moveTo(-x, -y);
        instance.smoothZoom(width / 2, height / 2, 0.5);
      }, 0);

      instance.on("transform", () => {
        popperInstance && popperInstance.update();
      });
    };

    onMounted(() => {
      initPanzoom();
    });

    return {
      handleMouseOver,
      handleMouseLeave,
      handleTooltipMouseLeave,
      tooltip,
      panzoomEl,
      tooltipId,
      handleAreaTap,
      config
    };
  }
};
</script>

<style lang="scss" scoped>
.area {
  cursor: pointer;
  position: absolute;
}

.map {
  cursor: move;
  z-index: 10;
  overflow: hidden;
  max-height: 100vh;

  &:focus, &:active {
    outline: none;
    border: none;
  }
}

#tooltip {
  display: none;
  z-index: 50;
}

#tooltip[data-show] {
  display: inline-block;
}

.tooltip {
  width: 300px;
  color: #fff;
  padding: 22px;
  font-family: Rubik;
  text-align: left;

  @media (max-width: 50em) {
    transform: none !important;
  }
}
</style>
