<template>
  <div class="donations">
    <div class="donations__body">
      <div class="donations__header">
        Donations
        <button
          @click="$emit('close')"
          class="donations__close"
        >
          <svg
            fill="none"
            height="22"
            viewBox="0 0 32 32"
            width="22"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M32 3.22286L28.7771 0L16 12.7771L3.22286 0L0 3.22286L12.7771 16L0 28.7771L3.22286 32L16 19.2229L28.7771 32L32 28.7771L19.2229 16L32 3.22286Z"
              fill="white"
              fill-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <p>This is an open-source project, we have no investors or sponsors. You can help us if you want.</p>

      <div class="wallet">
        <div class="wallet__icon">
          <svg
            fill="none"
            height="100%"
            viewBox="0 0 48 48"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M24.0186 8.68611e-06C10.7629 -0.0111476 0.0111649 10.7258 8.68611e-06 23.9814C-0.0111476 37.2371 10.7258 47.9889 23.9814 48C37.2354 47.9889 47.9796 37.2539 48 24C48 10.7517 37.2669 0.0111649 24.0186 8.68611e-06ZM23.9814 44.0063C12.9325 44.0063 3.97529 35.0489 3.97529 24C3.97529 12.9511 12.9325 3.99385 23.9814 3.99385C35.0266 4.00501 43.9764 12.9549 43.9876 24C43.9876 35.0489 35.0304 44.0063 23.9814 44.0063Z"
                fill="#00B1BF"
              />
              <path
                d="M19.8935 25.9297L15.2969 30.5568C16.7548 33.663 19.8378 35.85 23.4358 35.9977V29.8168C21.5172 29.5228 20.0226 27.9102 19.8935 25.9297Z"
                fill="white"
              />
              <path
                d="M33.7449 26.5187L33.7326 13.5428H33.7313V12L28.2582 17.5093V25.6529C28.2582 27.7579 26.7174 29.5059 24.7119 29.8177V36C29.7284 35.7894 33.7449 31.6177 33.7449 26.5187Z"
                fill="white"
              />
              <path
                d="M19.8869 24.0104V22.1024V19.7266L14.403 25.2468L14.4017 26.5188C14.4017 26.8731 14.4207 27.2232 14.4587 27.5665L12.1488 29.8917L12.1461 31.027L8.00195 35.1986V35.9741L19.8869 24.0104Z"
                fill="white"
              />
              <path
                d="M20.5086 12.3076H19.9882H19.9841H19.8904H19.8863H19.7219H19.6241H19.2939H19.2205H19.1974H19.1241H15.1796H15.0818H14.4119H14.3141H13.7937C14.1837 12.9286 14.4119 13.6631 14.4119 14.4482V14.9009L14.4038 21.4388L12.1442 23.7133L12.1496 24.8376L8 29.0147V29.7875L19.8863 17.8251V17.8238V14.8598L19.8904 14.4482C19.8904 13.6617 20.1173 12.9286 20.5086 12.3076Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  fill="white"
                  height="48"
                  rx="6"
                  width="48"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="wallet__name">UFO</div>
        <input
          class="wallet__input"
          size="34"
          type="text"
          value="UXaKH9taQDhPYvH7tQDgDRg1UaLWYXzLVZ"
        />
        <button
          @click="copy('UXaKH9taQDhPYvH7tQDgDRg1UaLWYXzLVZ')"
          class="wallet__copy"
        >
          <svg
            fill="none"
            height="18"
            viewBox="0 0 18 18"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 13H3C2.46957 13 1.96086 12.7893 1.58579 12.4142C1.21071 12.0391 1 11.5304 1 11V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H11C11.5304 1 12.0391 1.21071 12.4142 1.58579C12.7893 1.96086 13 2.46957 13 3V5M7 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </button>
      </div>

      <div class="wallet">
        <div class="wallet__icon">
          <svg
            fill="none"
            height="100%"
            viewBox="0 0 48 48"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M24.0186 8.68611e-06C10.7629 -0.0111476 0.0111649 10.7258 8.68611e-06 23.9814C-0.0111476 37.2371 10.7258 47.9889 23.9814 48C37.2354 47.9889 47.9796 37.2539 48 24C48 10.7517 37.2669 0.0111649 24.0186 8.68611e-06ZM23.9814 44.0063C12.9325 44.0063 3.97529 35.0489 3.97529 24C3.97529 12.9511 12.9325 3.99385 23.9814 3.99385C35.0266 4.00501 43.9764 12.9549 43.9876 24C43.9876 35.0489 35.0304 44.0063 23.9814 44.0063Z"
                fill="#F7931A"
              />
              <path
                d="M30.465 22.83C32.07 21.9253 32.9375 20.1143 32.6384 18.2975C32.2669 15.4926 28.9233 14.4337 26.2854 14.2108V9.99414H23.6848V14.1737H21.9387V9.99414H19.3381V14.1737H14.0996V16.8672H16.0501C16.9232 16.8672 17.3504 17.1273 17.3504 17.8331V29.4059C17.3504 30.3718 16.8674 30.6691 16.3473 30.6691H14.3597V33.4183H19.4123V37.6908H22.0315V33.4183H23.7776V37.6908H26.3225V33.4183H26.8798C32.2296 33.4183 34.013 30.7991 34.013 27.2326C33.9702 25.1297 32.512 23.3204 30.465 22.83ZM21.9386 17.0344H23.7962C25.0965 17.0344 27.66 17.2573 27.66 19.3749C27.7491 20.757 26.7479 21.9682 25.3751 22.1427H21.9386V17.0344ZM24.8179 30.4089V30.3904H21.9386V24.8176H25.2823C26.0625 24.8176 28.9974 25.0777 28.9974 27.1582C28.9974 29.2387 27.6043 30.4089 24.8179 30.4089Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  fill="white"
                  height="48"
                  width="48"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="wallet__name">BTC</div>
        <input
          class="wallet__input"
          size="34"
          type="text"
          value="34AHqRtQgQEd6HrSm6Lb5Unn6n4idJWaW6"
        />
        <button
          @click="copy('34AHqRtQgQEd6HrSm6Lb5Unn6n4idJWaW6')"
          class="wallet__copy"
        >
          <svg
            fill="none"
            height="18"
            viewBox="0 0 18 18"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 13H3C2.46957 13 1.96086 12.7893 1.58579 12.4142C1.21071 12.0391 1 11.5304 1 11V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H11C11.5304 1 12.0391 1.21071 12.4142 1.58579C12.7893 1.96086 13 2.46957 13 3V5M7 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </button>
      </div>

      <br />

      <p>If you want to support a Designer donate to him directly.</p>

      <div class="wallet">
        <div class="wallet__icon">
          <svg
            fill="none"
            height="100%"
            viewBox="0 0 48 48"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M24.0186 8.68611e-06C10.7629 -0.0111476 0.0111649 10.7258 8.68611e-06 23.9814C-0.0111476 37.2371 10.7258 47.9889 23.9814 48C37.2354 47.9889 47.9796 37.2539 48 24C48 10.7517 37.2669 0.0111649 24.0186 8.68611e-06ZM23.9814 44.0063C12.9325 44.0063 3.97529 35.0489 3.97529 24C3.97529 12.9511 12.9325 3.99385 23.9814 3.99385C35.0266 4.00501 43.9764 12.9549 43.9876 24C43.9876 35.0489 35.0304 44.0063 23.9814 44.0063Z"
                fill="#00B1BF"
              />
              <path
                d="M19.8935 25.9297L15.2969 30.5568C16.7548 33.663 19.8378 35.85 23.4358 35.9977V29.8168C21.5172 29.5228 20.0226 27.9102 19.8935 25.9297Z"
                fill="white"
              />
              <path
                d="M33.7449 26.5187L33.7326 13.5428H33.7313V12L28.2582 17.5093V25.6529C28.2582 27.7579 26.7174 29.5059 24.7119 29.8177V36C29.7284 35.7894 33.7449 31.6177 33.7449 26.5187Z"
                fill="white"
              />
              <path
                d="M19.8869 24.0104V22.1024V19.7266L14.403 25.2468L14.4017 26.5188C14.4017 26.8731 14.4207 27.2232 14.4587 27.5665L12.1488 29.8917L12.1461 31.027L8.00195 35.1986V35.9741L19.8869 24.0104Z"
                fill="white"
              />
              <path
                d="M20.5086 12.3076H19.9882H19.9841H19.8904H19.8863H19.7219H19.6241H19.2939H19.2205H19.1974H19.1241H15.1796H15.0818H14.4119H14.3141H13.7937C14.1837 12.9286 14.4119 13.6631 14.4119 14.4482V14.9009L14.4038 21.4388L12.1442 23.7133L12.1496 24.8376L8 29.0147V29.7875L19.8863 17.8251V17.8238V14.8598L19.8904 14.4482C19.8904 13.6617 20.1173 12.9286 20.5086 12.3076Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  fill="white"
                  height="48"
                  rx="6"
                  width="48"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="wallet__name">UFO</div>
        <input
          class="wallet__input"
          size="34"
          type="text"
          value="UchvuofYUFQrrhsn2kXBfYKbqyvQ4vQNo6"
        />
        <button
          @click="copy('UchvuofYUFQrrhsn2kXBfYKbqyvQ4vQNo6')"
          class="wallet__copy"
        >
          <svg
            fill="none"
            height="18"
            viewBox="0 0 18 18"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 13H3C2.46957 13 1.96086 12.7893 1.58579 12.4142C1.21071 12.0391 1 11.5304 1 11V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H11C11.5304 1 12.0391 1.21071 12.4142 1.58579C12.7893 1.96086 13 2.46957 13 3V5M7 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useClipboard from "vue-clipboard3";

export default {
  setup() {
    const { toClipboard } = useClipboard();

    const copy = async str => {
      try {
        await toClipboard(str);
      } catch (e) {
        console.error(e);
      }
    };

    return { copy };
  }
};
</script>

<style lang="scss" scoped>
.donations {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Rubik;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  text-align: left;
  z-index: 500;

  &__body {
    position: relative;
    width: 690px;
    max-width: 90vw;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Rubik;
    font-size: 64px;
    line-height: 64px;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 28px;
    }
  }

  &__close {
    cursor: pointer;
    background: transparent;
    border: none;
  }

  p {
    font-size: 22px;
    line-height: 33px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

}

.wallet + .wallet {
  margin-top: 16px;
}

.wallet {
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: #1c1c23;
  border-radius: 10px;
  overflow: hidden;
  height: 80px;

  @media (max-width: 768px) {
    padding: 0 16px;
    height: 56px;
  }

  &__icon {
    width: 48px;
    height: 48px;

    @media (max-width: 768px) {
      height: 32px;
      width: 32px;
    }
  }

  &__name {
    margin: 0 10px;
    font-weight: bold;
    font-family: Source Code Pro, monospace;
  }

  &__input {
    border: none;
    font-family: Source Code Pro, monospace;
    background: none;
    font-size: 18px;
    margin-left: auto;
    margin-right: 10px;
    color: #fff;
    min-width: 0;

    &:focus {
      outline: none;
    }

    @media (max-width: 768px) {
      font-size: 11px;
      line-height: 24px;
    }
  }

  &__copy {
    cursor: pointer;
    background: none;
    border: none;
    &:active {
      opacity: 0.5;
    }
  }
}

</style>
