<template>
  <div
    class="tooltip__body"
    v-if="currentTooltipId === tooltipId"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    currentTooltipId: String,
    tooltipId: String
  },
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.tooltip {
  &__body {
    padding: 22px;
    background-color: #1c1c23;
    border-radius: 6px;

    @media (max-width: 50em) {
      position: fixed;
      top: 24px;
      right: 24px;
      left: 24px;
      padding: 32px;
    }
  }
}
</style>
